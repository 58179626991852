






































































import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { Location } from "@/types/vimbApi/erlaubnisinhaber/location";

type Fields = {
  name?: boolean;
  street?: boolean;
  houseNumber?: boolean;
  co?: boolean;
  zip?: boolean;
  city?: boolean;
  state?: boolean;
  country?: boolean;
};

const fieldsDefault: Fields = {
  name: false,
  street: true,
  houseNumber: true,
  co: false,
  zip: true,
  city: true,
  state: false,
  country: false
};

export default defineComponent({
  props: {
    vModel: { type: Object as PropType<Location[]> },
    title: { type: String, default: "Adresse" },
    fields: {
      type: Object as PropType<Fields>,
      default: () => fieldsDefault
    }
  },
  setup(props) {
    const fieldsConfig = ref<Fields>({ ...fieldsDefault });
    watch(
      () => props.fields,
      fields =>
        (fieldsConfig.value = {
          ...fieldsDefault,
          ...fieldsConfig.value,
          ...fields
        }),
      { immediate: true }
    );
    return { fieldsConfig, form: ref() };
  }
});
