
































































































































import Vue from "vue";
import {
  Vertreter,
  VertreterType
} from "@/types/vimbApi/erlaubnisinhaber/vertreter";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    vModel: { type: Array as PropType<Vertreter[]>, default: () => [] }
  },
  setup(props) {
    const selected = ref<number>();
    const current = ref<Vertreter>();
    const editOpened = ref<boolean>(false);
    const create = ref<boolean>(false);
    const firstField = ref();
    const edit = (edit: Vertreter | true = true) => {
      create.value = edit === true;
      if (edit !== true) current.value = edit;
      else current.value = {};
      editOpened.value = true;
      Vue.nextTick(() => firstField?.value?.focus());
    };
    const save = (vertreter: Vertreter) => {
      if (create.value) props.vModel.push({ ...vertreter, active: true });
      editOpened.value = false;
    };
    return {
      create,
      editOpened,
      current,
      selected,
      edit,
      save,
      funktionen: Object.values(VertreterType),
      form: ref(),
      firstField
    };
  }
});
