var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"flex"},[_c('v-card-title',[_vm._v(" Tätigkeiten ")]),_c('v-card-text',[(_vm.vModel)?_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.vModel),function(taetigkeit,type){return _c('v-col',{key:type,staticClass:"d-flex",attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{staticClass:"mr-2",attrs:{"disabled":_vm.isUsed(taetigkeit.type) && !_vm.isOwn(taetigkeit.type),"rules":[
              function (v) { return _vm.hasActive(); },
              function (v) { return !!v ||
                taetigkeit.type !== _vm.TaetigkeitType.TYPE34D ||
                (_vm.isUsed(taetigkeit.type) && !_vm.isOwn(taetigkeit.type)); }
            ],"label":taetigkeit.type},model:{value:(taetigkeit.active),callback:function ($$v) {_vm.$set(taetigkeit, "active", $$v)},expression:"taetigkeit.active"}}),(!_vm.is34c(taetigkeit))?_c('v-text-field',{staticClass:"flex",attrs:{"disabled":!taetigkeit.active,"label":"Registernummer","rules":[
              function (v) { return (!!v && [14, 15].includes(v.length)) || !taetigkeit.active; }
            ]},model:{value:(taetigkeit.registernummer),callback:function ($$v) {_vm.$set(taetigkeit, "registernummer", $$v)},expression:"taetigkeit.registernummer"}},[(taetigkeit.active && taetigkeit.registernummer)?_c('v-btn',{attrs:{"slot":"append","small":"","icon":"","title":"Im Vermittlerregister anzeigen","target":"_blank","href":'https://www.vermittlerregister.info/recherche?registernummer=' +
                  taetigkeit.registernummer,"disabled":![14, 15].includes(taetigkeit.registernummer.length)},slot:"append"},[_c('v-icon',[_vm._v("fas fa-search")])],1):_vm._e()],1):_vm._e(),(_vm.is34c(taetigkeit))?_c('v-text-field',{staticClass:"flex",attrs:{"disabled":!taetigkeit.active,"label":"Ausstellende Behörde","rules":[function (v) { return !!v || !taetigkeit.active; }]},model:{value:(taetigkeit.ausstellendeBehoerde),callback:function ($$v) {_vm.$set(taetigkeit, "ausstellendeBehoerde", $$v)},expression:"taetigkeit.ausstellendeBehoerde"}}):_vm._e()],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }