














































































import { defineComponent } from "@vue/composition-api";
import { Handelsstand } from "@/types/vimbApi/erlaubnisinhaber/handelsstand";
import {
  Geschaeftsform,
  geschaeftsformenConfig
} from "@/types/vimbApi/erlaubnisinhaber/geschaeftsform";

export default defineComponent({
  props: {
    vModel: { type: Object }
  },
  setup() {
    return {
      handelsstaende: Object.values(Handelsstand),
      geschaeftsformen: Object.values(Geschaeftsform),
      geschaeftsformenConfig: (geschaeftsform = Geschaeftsform.UNKNOWN) =>
        geschaeftsformenConfig[geschaeftsform]
    };
  }
});
