






























import {
  Erlaubnisumfang,
  ErlaubnisumfangType
} from "@/types/vimbApi/erlaubnisinhaber/erlaubnisumfang";
import { defineComponent, PropType } from "@vue/composition-api";

const labels = {
  [ErlaubnisumfangType.T34F_ABS1_NR1]:
    "Anteilen oder Aktien an inländischen offenen Investmentvermögen, offenen " +
    "EU-Investmentvermögen oder ausländischen ofenen Investmentvermögen, die " +
    "nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen " +
    "(§ 34f Abs. 1 Nr. 1 GewO)",
  [ErlaubnisumfangType.T34F_ABS1_NR2]:
    "Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen, " +
    "geschlossenen EU-Investmentvermögen oder ausländischen geschlossenen " +
    "Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben " +
    "werden dürfen (§ 34f Abs. 1 Nr. 2 GewO)",
  [ErlaubnisumfangType.T34F_ABS1_NR3]:
    "Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagengesetzes " +
    "(§ 34f Abs. 1 Nr. 3 GewO)"
};

export default defineComponent({
  props: {
    vModel: { type: Object as PropType<Erlaubnisumfang> }
  },
  setup() {
    return {
      labels,
      ErlaubnisumfangType
    };
  }
});
