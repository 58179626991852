






























































import { defineComponent, PropType } from "@vue/composition-api";
import { Routes } from "@/router/types";
import { Taetigkeit } from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";

export default defineComponent({
  props: {
    data: { type: Array as PropType<Erlaubnisinhaber[]>, default: () => [] },
    create: { type: Boolean, default: false },
    createInhaber: { type: Object, default: () => ({}) }
  },
  setup: () => {
    const filterTaetigkeiten = (taetigkeiten: Taetigkeit[] = []) =>
      Object.fromEntries(
        Object.entries(taetigkeiten).filter(([, value]) => value.active)
      );
    return {
      Routes,
      filterTaetigkeiten
    };
  }
});
