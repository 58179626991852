import {
  Actions,
  Getters,
  States
} from "@/store/modules/erlaubnisinhaber/types";
import { Modules } from "@/store/types";
import { useStore } from "../useStore";

export const useErlaubnisinhaber = (autoload = false) => {
  const { useState, useActions, useGetters } = useStore(
    Modules.ERLAUBNISINHABER
  );
  const {
    [States.LIST]: list,
    [States.LIST_LOADING]: listLoading,
    [States.LOADING]: loading
  } = useState([States.LIST, States.LIST_LOADING, States.LOADING]);
  const {
    [Actions.LOAD_LIST]: loadList,
    [Actions.LOAD]: load,
    [Actions.CREATE_NEW]: createNew,
    [Actions.SAVE]: save,
    [Actions.DELETE]: remove
  } = useActions([
    Actions.LOAD_LIST,
    Actions.LOAD,
    Actions.CREATE_NEW,
    Actions.SAVE,
    Actions.DELETE
  ]);
  const { [Getters.GET_USED_TAETIGKEITEN]: getUsedTaetigkeiten } = useGetters([
    Getters.GET_USED_TAETIGKEITEN
  ]);
  if (autoload) loadList();
  return {
    list,
    listLoading,
    loadList,
    load,
    createNew,
    save,
    loading,
    getUsedTaetigkeiten,
    remove
  };
};
