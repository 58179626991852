






















































































































import {
  Location,
  LocationType
} from "@/types/vimbApi/erlaubnisinhaber/location";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import ErlaubnisinhaberAnschrift from "@/components/settings/erlaubnisinhaber/Anschrift.vue";

export default defineComponent({
  props: {
    vModel: { type: Array as PropType<Location[]>, default: () => [] }
  },
  components: { ErlaubnisinhaberAnschrift },
  setup(props) {
    const selected = ref<number>();
    const current = ref<Location>();
    const editOpened = ref<boolean>(false);
    const create = ref<boolean>(false);
    const edit = (edit: Location | true = true) => {
      create.value = edit === true;
      if (edit !== true) current.value = edit;
      else current.value = { type: LocationType.PERSONENHANDELSGESELLSCHAFT };
      editOpened.value = true;
    };
    const save = (location: Location) => {
      if (create.value) props.vModel.push({ ...location, active: true });
      editOpened.value = false;
    };
    return {
      create,
      editOpened,
      current,
      selected,
      edit,
      save,
      form: ref()
    };
  }
});
