



















import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    vModel: { type: Object as PropType<Erlaubnisinhaber> }
  },
  setup() {
    return { all: ref<boolean>() };
  }
});
