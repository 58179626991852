





































































import { defineComponent, PropType } from "@vue/composition-api";
import {
  Taetigkeit,
  TaetigkeitType
} from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { useErlaubnisinhaber } from "@/lib/vimbApi/useErlaubnisinhaber";

export default defineComponent({
  props: {
    vModel: {
      type: Object as PropType<{ [key in TaetigkeitType]: Taetigkeit }>,
      default: () => ({})
    }
  },
  setup(props) {
    const { getUsedTaetigkeiten } = useErlaubnisinhaber();
    const taetigkeiten = () => Object.values(props?.vModel || {});
    const isActive = ({ active }: Taetigkeit) => !!active;
    const countActive = () => taetigkeiten().filter(isActive).length;
    const hasActive = () => !!countActive();
    const is34c = ({ type }: Taetigkeit) => type === TaetigkeitType.TYPE34C;
    const isOwn = (type: TaetigkeitType) =>
      Object.values(props.vModel)
        .filter(({ active }) => active)
        .find(taetigkeit => taetigkeit.type === type);
    const isUsed = (type: TaetigkeitType) =>
      getUsedTaetigkeiten().includes(type);
    return { hasActive, is34c, isUsed, isOwn, TaetigkeitType };
  }
});
